/// <reference types="@types/googlemaps" />
import { Component, OnInit } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import * as _moment from 'moment';
import { ApiService } from '~common/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GPSService } from '~common/gps.service';
const moment = (_moment as any).default ? (_moment as any).default : _moment;
declare let google: any;

@Component({
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ReceiptViewComponent implements OnInit {

  zoom = 18;
  sourceAddress = '';
  destinationAddress = '';
  totalRideTime: string;
  gpsList: any;
  origin: any = {};
  destination: any = {};
  waypoints = [];
  waitTime: any;
  map: any;
  mapAPILoaded = false;
  account: any;
  trip: any;
  loading = false;

  private directionIcon = {
    path: 'm14.650839,17.10804l-7.61407,-6.335771c4.757582,-0.045325 5.319359,'
      + '0.04921 9.44757,0.073815l10.552428,6.632326c-4.278937,2.757696 -5.760671,'
      + '4.11679 -9.829817,6.734626l-10.170181,-0.02849c3.673739,-3.27656 4.545096,'
      + '-4.191037 7.61407,-7.076506z',
    rotation: 0,
    fillColor: 'black',
    fillOpacity: 1.0,
    strokeColor: '#000000',
    strokeWidth: 5,
  };

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private gps: GPSService,
  ) {
  }

  async ngOnInit() {
    await this.mapsAPILoader.load();
    this.mapAPILoaded = true;

    this.route.queryParams.subscribe((params) => {
      const { code, city, date } = params;
      if (!code) {
        return this.errorRedirect('No receipt code provided');
      }
      if (!city) {
        return this.errorRedirect('No city provided');
      }
      if (!date) {
        return this.errorRedirect('No date provided');
      }
      
      this.loading = true;
      const payload = { code, city, date };
      this.api.post('receipt/find', payload)
        .subscribe((res) => {
          this.loadTrip(res.meta_data.trip);
          this.loading = false;
        }, (err) => {
          this.loading = false;
          let message = 'Network connectivity error. Please check your internet connection';
          switch (err.status) {
            case 404:
              message = err.error.message;
              break;
            case 500:
              message = 'Failed to retrive trip details. Please try again';
              break;
          }
          this.errorRedirect(message);
        });
    });
  }

  loadTrip(trip) {
    this.trip = trip;
    this.waitTime = moment.utc(trip.meterDetails.waitTime * 1000).format('HH:mm:ss');
    this.diff(trip.start.time, trip.end.time);
    this.origin = { lat: Number(trip.start.location.lat), lng: Number(trip.start.location.long) };
    this.destination = { lat: Number(trip.end.location.lat), lng: Number(trip.end.location.long) };
    this.getAddress(trip.start.location.lat, trip.start.location.long, 'source');
    this.getAddress(trip.end.location.lat, trip.end.location.long, 'destination');
    this.getData(trip);
    if (this.map) {
      this.mapReady(this.map);
    }
  }

  print() {
    window.print();
  }

  back() {
    this.router.navigate(['/receipt']);
  }
  
  getDistanceString() {
    const distance = this.trip.meterDetails.movingDistance.toFixed(2);
    if (this.trip.meterDetails.unit && this.trip.meterDetails.unit.toLowerCase() === 'imperial') {
      return `${distance} M`;
    }
    return `${distance} KM`
  }

  async getAddress(lat, long, type) {
    const geocoder = new google.maps.Geocoder();
    const location = new google.maps.LatLng(lat, long);
    geocoder.geocode({ location }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results[0]) {
        this.setAddress(results[0].formatted_address, type);
      }
    });
  }

  setAddress(address, type) {
    if (type === 'source') {
      this.sourceAddress = address;
    } else {
      this.destinationAddress = address;
    }
  }

  diff(start, end) {
    start = moment(start).format('HH:mm:ss');
    end = moment(end).format('HH:mm:ss');
    start = start.split(':');
    end = end.split(':');
    const startDate = new Date(0, 0, 0, start[0], start[1], 0);
    const endDate = new Date(0, 0, 0, end[0], end[1], 0);
    let diff = endDate.getTime() - startDate.getTime();
    const hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(diff / 1000 / 60);

    this.totalRideTime = (hours < 9 ? '0' : '') + hours + ':' + (minutes < 9 ? '0' : '') + minutes;
  }

  calculateTotalMeter(trip) {
    return trip.meterDetails.meterCharge
        + trip.meterDetails.extra
        + trip.meterDetails.bookingFee
        + trip.meterDetails.hiringFee
        + trip.meterDetails.levy
        + trip.meterDetails.tax
        + trip.meterDetails.tolls;
  }

  getData(trip) {
    const data = {
      from: trip.start.time,
      to: trip.end.time,
      imei: trip.vehicleId.IMEI,
    };
    this.gps.post('gps/list', data).subscribe((res) => {
      this.gpsList = res.meta_data.gps || [];
      this.gpsList.forEach(this.createGPSMarker.bind(this));
    });
  }

  mapReady(map) {
    this.map = map;
    const originLatLng = new google.maps.LatLng(this.origin.lat, this.origin.lng);
    const destinationLatLng = new google.maps.LatLng(this.destination.lat, this.destination.lng);
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(originLatLng);
    bounds.extend(destinationLatLng);
    this.map.fitBounds(bounds);
  }

  createGPSMarker(location) {
    const addressData = location.addr || location.address;
    const address = addressData ? [
      addressData.streetNumber,
      addressData.street,
      addressData.city,
      addressData.state,
      addressData.zipCode,
    ].join(' ') : null;

    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(location.lat, location.lng || location.long),
      map: this.map,
      title: address,
    });
    const icon = { ...this.directionIcon };
    icon.rotation = location.heading - 90;
    icon.fillColor = this.getSpeedColor(location.speed);
    // @ts-ignore
    icon.anchor = new google.maps.Point(16, 16);
    // @ts-ignore
    marker.data = { location, address };
    marker.setIcon(icon);

    return marker;
  }

  getSpeedColor(speed) {
    const RED = 'rgb(243,0,0)';
    const AMBER = 'rgb(255,255,0)';
    const GREEN = 'rgb(102,255,102)';

    if (speed <= 5) {
      return AMBER;
    } else if (speed >= 60) {
      return RED;
    } else {
      return GREEN;
    }
  }

  errorRedirect(message: string) {
    this.router.navigate(['/receipt'], { queryParams: { error: message }});
  }
}
