import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { MaterialModule } from '~common/material';
import { ReceiptModule } from 'receipt/receipt.module';
import { HttpClientModule } from '@angular/common/http';

const routes: Routes = [
  { path: '', redirectTo: 'receipt', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBdiNxE31mGELQwmau05oOcld_1Kk2ED0s',
      libraries: ['places', 'geometry'],
    }),
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    FormsModule,
    ReceiptModule,
    RouterModule.forRoot(routes, {useHash: true}),
  ],
  providers: [
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
