import { Injectable, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import * as io from 'socket.io-client';
import { Env } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class GPSService {
  private baseUrl = Env.gpsUrl;
  private socket: SocketIOClient.Socket;
  private imeiSubscriptions: Array<string> = [];

  public data: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
  ) {}

  connect() {
    if (this.socket) {
      return false;
    }

    return new Promise((resolve, reject) => {
      this.socket = io(this.baseUrl, {
        query: {
          accessToken: null,//this.auth.getToken()
        },
        transports: ['websocket'],
      });
      this.socket.on('connect', resolve);
      this.socket.on('error', reject);

      // Handle incoming gps data
      this.socket.on('gpsDetails', this.handleData.bind(this));
    });
  }

  handleConnect() {
    // On reconnect, re-subscribe to existing imeis being watched
    if (this.imeiSubscriptions.length > 0) {
      this.subscribeToImeis(this.imeiSubscriptions);
    }
  }

  send(event: string, data: any, callback?: Function) {
    if (this.socket) {
      if (callback) {
        this.socket.emit(event, data, callback);
      } else {
        this.socket.emit(event, data);
      }
    }
  }

  listen(event: string, fn: Function) {
    this.socket.on(event, fn);
  }

  subscribeToImeis(imeis: Array<string>) {
    if (imeis.length > 0) {
      this.socket.emit('gps/subscribe-for-devices', { devices: imeis });
    }
  }

  unsubscribeToImeis(imeis: Array<string>) {
    if (imeis.length > 0) {
      this.socket.emit('gps/unsubscribe-from-devices', { devices: imeis });
    }
  }

  handleData(response) {
    const gps = response.data.gps;
    if (!gps) {
      return;
    }

    this.data.emit(gps);
  }

  get(url: string) {
    return this.http.get(this.getUrl(url));
  }

  post(url: string, body: Object):any {
    return this.http.post(this.getUrl(url), body);
  }

  put(url: string, body: Object) {
    return this.http.put(this.getUrl(url), body);
  }

  delete(url: string) {
    return this.http.delete(this.getUrl(url));
  }

  private getUrl(url: string) {
    return Location.joinWithSlash(this.baseUrl, `/api/v1/${url}`);
  }
}
