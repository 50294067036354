<div *ngIf="loading" class="page-alert">
  <h4>Loading...</h4>
  <p>Please wait while we fetch your trip details</p>
</div>
<div *ngIf="!loading && trip" style="overflow: auto;">
  <div fxLayout="row">
      <div fxFlex.gt-sm="66%">
    
        <mat-card fxLayout="row">
          <mat-card-content>
            <agm-map [zoom]="zoom" [fitBounds]="true"
              [latitude]="trip.start.location.lat" [longitude]="trip.start.location.long"
              (mapReady)="mapReady($event)">
              <agm-marker [latitude]="origin.lat" [longitude]="origin.lng" title="Pickup" [agmFitBounds]="true"></agm-marker>
              <agm-marker [latitude]="destination.lat" [longitude]="destination.lng" title="Dropoff" [agmFitBounds]="true"></agm-marker>
            </agm-map>
          </mat-card-content>
        </mat-card>
    
        <mat-card fxLayout="row" class="address-card">
          <div fxLayout="row">
            <div fxFlex.gt-sm="10%">
              <img class="map-marker" src="assets/images/map-marker-source.png" width="32" height="32" />
            </div>
            <div fxFlex.gt-sm="40%">
              Source - <b> {{trip.start.time | amDateFormat:'DD-MM-YYYY hh:mmA'}}</b><br>
              <label class="address-label" *ngIf="sourceAddress">{{sourceAddress}}</label>
            </div>
            <div fxFlex.gt-sm="10%">
              <img class="map-marker" src="assets/images/map-marker-destination.png" width="32" height="32" />
            </div>
            <div fxFlex.gt-sm="40%">
              Destination - <b> {{trip.end.time | amDateFormat:'DD-MM-YYYY hh:mmA' }}</b><br>
              <label class="address-label" *ngIf="sourceAddress">{{destinationAddress}}</label>
            </div>
          </div>
        </mat-card>
    
        <div fxLayout="row">
          <mat-card fxFlex.gt-sm="50%">
            <mat-card-content>
              <table class="table">
                <tbody>
                  <tr>
                    <td class="wd-50"><b>CAR</b></td>
                    <td class="wd-50"><b>DRIVER</b></td>
                  </tr>
                  <tr>
                    <td class="wd-50">{{trip.vehicleId.vehicleRegistrationNumber}}</td>
                    <td class="wd-50">{{trip.driverId.firstName}} {{trip.driverId.lastName}}</td>
                  </tr>
                  <tr>
                    <td class="wd-50"><b>COMPANY</b></td>
                    <td class="wd-50"><b>TAX ID / ABN</b></td>
                  </tr>
                  <tr>
                    <td class="wd-50">{{ trip.vehicleId.fleet.name }}</td>
                    <td class="wd-50">{{ trip.driverId.customId }}</td>
                  </tr>
                  <tr *ngIf="trip.vehicleId.fleet.address">
                    <td colspan="2"><b>ADDRESS</b></td>
                  </tr>
                  <tr *ngIf="trip.vehicleId.fleet.address">
                    <td colspan="2">{{ trip.vehicleId.fleet.address }}</td>
                  </tr>
                  <tr *ngIf="trip.vehicleId.fleet && trip.vehicleId.fleet.logo">
                    <td><img [src]="trip.vehicleId.fleet.logo" width="150"></td>
                  </tr>
                </tbody>
              </table>
            </mat-card-content>
          </mat-card>
    
          <mat-card fxFlex.gt-sm="50%">
            <mat-card-content>
              <h4>RIDE HISTORY</h4>
              <table class="table">
                <tbody>
                  <tr>
                    <td class="wd-50">Request Type</td>
                    <td class="wd-50">{{trip.requestType}}</td>
                  </tr>
                  <tr>
                    <td class="wd-50">Passenger Name</td>
                    <td class="wd-50">{{trip.passengers.length>0?trip.passengers[0].name:'NA'}}</td>
                  </tr>
                  <tr>
                    <td class="wd-50">Payment Type</td>
                    <td class="wd-50">{{trip.payment}}</td>
                  </tr>
                  <tr>
                    <td class="wd-50">Ride Type</td>
                    <td class="wd-50">{{trip.rideType || 'Normal' }}</td>
                  </tr>
                </tbody>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    
      <div fxFlex.gt-sm="33%">
        <div fxLayout="row" class="toolbar">
          <div fxFlex.gt-sm="80%"></div>
          <div fxFlex.gt-sm="10%" (click)="back()">
            Back
          </div>
          <div fxFlex.gt-sm="10%">
            <mat-icon (click)="print()">print</mat-icon>
          </div>
        </div>
        <mat-card>
          <mat-card-content>
            <h4>TRAVEL INFORMATION</h4>
            <table class="table">
              <tbody>
                <tr>
                  <td class="wd-50">Distance</td>
                  <td class="wd-50">{{getDistanceString()}}</td>
                </tr>
                <tr>
                  <td class="wd-50">Total ride time</td>
                  <td class="wd-50">{{totalRideTime+':00'}}</td>
                </tr>
                <tr>
                  <td class="wd-50">Waiting time</td>
                  <td class="wd-50" *ngIf="waitTime">{{waitTime}}</td>
                </tr>
                <tr>
                  <td class="wd-50">Mode</td>
                  <td class="wd-50">{{trip.feeType}}</td>
                </tr>
                <tr>
                  <td class="wd-50">Status</td>
                  <td class="wd-50">{{trip.rideStatus}}</td>
                </tr>
                <tr>
                  <td class="wd-50">Meter shift</td>
                  <td class="wd-50">{{trip.shiftTitle}}</td>
                </tr>
              </tbody>
            </table>
            <mat-divider></mat-divider>
            <h4>FARE BREAK DOWN</h4>
            <table class="table">
              <tbody>
    
                <tr>
                  <td class="wd-50">Meter</td>
                  <td class="wd-50">$ {{trip.meterDetails.meterCharge | number:'1.2-2'}}</td>
                </tr>
                <tr>
                  <td class="wd-50">Levy</td>
                  <td class="wd-50">$ {{trip.meterDetails.levy | number:'1.2-2'}}</td>
                </tr>
                <tr>
                  <td class="wd-50">Hiring</td>
                  <td class="wd-50">$ {{trip.meterDetails.hiringFee | number:'1.2-2'}}</td>
                </tr>
                <tr>
                  <td class="wd-50">Booking</td>
                  <td class="wd-50">$ {{trip.meterDetails.bookingFee | number:'1.2-2'}}</td>
                </tr>
                <tr>
                  <td class="wd-50">Tolls</td>
                  <td class="wd-50">$ {{trip.meterDetails.tolls | number:'1.2-2'}}</td>
                </tr>
                <tr>
                  <td class="wd-50">Extra</td>
                  <td class="wd-50">$ {{trip.meterDetails.extra | number:'1.2-2'}}</td>
                </tr>
                <tr>
                  <td class="wd-50">Tax</td>
                  <td class="wd-50">$ {{trip.meterDetails.tax | number:'1.2-2'}}</td>
                </tr>
    
                <mat-divider class="divider"></mat-divider>
    
                <tr>
                  <td class="wd-50">Total Meter</td>
                  <td class="wd-50">$ {{calculateTotalMeter(trip) | number:'1.2-2' }}</td>
                </tr>
    
                <mat-divider class="divider"></mat-divider>
    
                <tr>
                  <td class="wd-50">Tip</td>
                  <td class="wd-50">$ {{trip.meterDetails.tip | number:'1.2-2'}}</td>
                </tr>
    
                <mat-divider class="divider"></mat-divider>
    
                <tr>
                  <td class="wd-50">Credit Card Charge</td>
                  <td class="wd-50">$ {{trip.meterDetails.creditCardFee | number:'1.2-2'}}</td>
                </tr>
    
                <mat-divider class="divider"></mat-divider>
    
                <tr>
                  <td><h3>Total Fare</h3></td>
                  <td><h3>$ {{trip.meterDetails.amount | number:'1.2-2'}}</h3></td>
                </tr>
    
              </tbody>
            </table>
    
          </mat-card-content>
        </mat-card>
      </div>
  </div>
</div>