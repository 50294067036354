<div class="container-fluid landing">
    <div class="row">
        <div class="col-md-6 landing-left">
            <img src="../../assets/beamCloud.png" width="200" height="171" alt="beamLive" class="landing-logo">
            <h1>Mobile IoT Communication</h1>
            <h2>Augments Human Intelligence with Knowledge</h2>
            <h2>Extracted Live from Internet of Things</h2>
        </div>
        <div class="col-md-6 d-flex align-items-center justify-content-center">
            <form #loginForm="ngForm" (submit)="onSubmit(loginForm)" class="login-block" name="loginForm">
                <div *ngIf="errorMessage" class="form-error">{{ errorMessage }}</div>
                <div class="form-group w-100">
                    <input class="form-control width-control-login"
                        type="text"
                        name="code"
                        placeholder="Receipt Code"
                        [(ngModel)]="code">
                </div>
                <div class="form-group w-100">
                    <button class="green-btn btn-button clear-outline width-control-login"
                        [disabled]="loading">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<footer class="text-center w-100 d-block">
    Copyright &copy; 2019 beamLive
</footer>