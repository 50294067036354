import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { AgmCoreModule } from '@agm/core';
import { CdkTableModule } from '@angular/cdk/table';
import { AgmDirectionModule } from 'agm-direction';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TextMaskModule } from 'angular2-text-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularDraggableModule } from 'angular2-draggable';
import { MomentModule } from 'ngx-moment';

import { ReceiptFormComponent } from './form/form.component';
import { MaterialModule } from '~common/material';
import { ReceiptViewComponent } from './view/view.component';

const routes: Routes = [
  { path: 'receipt', component: ReceiptFormComponent },
  { path: 'receipt/view', component: ReceiptViewComponent },
];

@NgModule({
  declarations: [
    ReceiptFormComponent,
    ReceiptViewComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule,
    AgmDirectionModule,
    TextMaskModule,
    NgSelectModule,
    MaterialModule,
    FlexLayoutModule,
    CdkTableModule,
    MomentModule,
    AngularDraggableModule,

    RouterModule.forChild(routes),
  ],
  providers: [
  ],
})
export class ReceiptModule {}
