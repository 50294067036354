import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Env } from '../../../environments/environment';

import { ApiService } from '~common/api.service';

@Component({
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class ReceiptFormComponent {
  errorMessage: string;
  loading = false;
  code: string;

  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  onSubmit(form: NgForm) {
    this.errorMessage = null;
    const { code } = form.value;
    if (!code) {
      this.errorMessage = 'Enter your receipt code';
      return;
    }
    window.open(`${
      Env.sharedUrl
    }/ride-summary?pageId=${code}`, "_blank");
  }
}
