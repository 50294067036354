import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { Env } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl = Location.joinWithSlash(Env.apiUrl, Env.apiVersion);

  constructor(private http: HttpClient) {
  }

  getMedia(url: string): Observable<Blob> {
    return this.http.get(`${Env.apiUrl}${url}`, { responseType: 'blob' });
  }

  get(url: string): any {
    const httpOptions = this.getOptions();
    return this.http.get(this.getUrl(url), httpOptions);
  }

  post(url: string, body: Object): any {
    const httpOptions = this.getOptions();
    return this.http.post(this.getUrl(url), body, httpOptions);
  }

  put(url: string, body: Object):any {
    const httpOptions = this.getOptions();
    return this.http.put(this.getUrl(url), body, httpOptions);
  }

  delete(url: string):any {
    const httpOptions = this.getOptions();
    return this.http.delete(this.getUrl(url), httpOptions);
  }

  private getUrl(url: string) {
    return Location.joinWithSlash(this.baseUrl, url);
  }

  private getOptions() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const token = null;//this.auth.getToken();
    if (token !== null && token !== '') {
      httpOptions.headers = httpOptions.headers.append('Authorization', `Bearer ${token}`);
    }

    return httpOptions;
  }
}
